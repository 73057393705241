import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import './Carousel_mangaPopulaire.css';
import config from "../config";


function MobileCarouselMangaPopulaire({ base_url }) {
    const [items, setItems] = useState(Array(3).fill({}));
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDataLoaded, setIsDataLoaded] = useState(false); // Ajout de l'état pour vérifier si les données sont chargées
    const navigate = useNavigate();

    const handleItemClick = (title) => {
        navigate(`/manga/${encodeURIComponent(title)}`);
    };

    useEffect(() => {
        fetch(base_url + `${config.api}?action=getPopularMangas`)
            .then(response => response.json())
            .then(data => {
                setItems(data);
                setIsDataLoaded(true); // Définir isDataLoaded sur true une fois les données chargées
            })
            .catch(error => console.error('Error fetching popular mangas:', error));
    }, [base_url]);

    return (
        <div className="carousel-wrapper-mangapopulaire">
            <div className={"HSpace"}></div>
            <div className='text-collection-manga-Populaire'>Manga Populaires</div>
            <Swiper
                className={"swiper-mangapopulaire"}
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                centeredSlides={false}
                spaceBetween={0}
                slidesPerView={1.87}
            >
                {items.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div
                            className={`slide-mobile-mangapopulaire progressive-appear2 ${index === currentIndex ? "centered" : index === currentIndex - 1 || index === currentIndex + 1}`}
                            onClick={() => handleItemClick(item.title)}
                            style={{backgroundImage: `url(${base_url + item.image})`}}
                        >
                            <div
                                className={`bg-img-carousel ${isDataLoaded ? 'hide-background' : ''}`}> {/* Ajouter la classe conditionnellement */}
                                <div className="slide-content-mobile-mangapopulaire">

                                </div>
                                <div className="item-all-app-mobile-mangaPopulaire" onClick={handleItemClick}>
                                    <div className={`bg-img-icon-carousel ${isDataLoaded ? 'hide-background' : ''}`}>
                                        {item.icon ? (
                                            <img src={`${base_url}${item.icon}`} alt={item.title}
                                                 className="item-image-all-gallery-mobile progressive-appear"/>
                                        ) : (
                                            <div className="item-image-all-gallery-mobile"/> // Affichez un élément de remplacement ici
                                        )}
                                    </div>
                                    <div className={"item-icon-text-manga-populaire"}>
                                        <h3 className="item-title-mobile progressive-appear">{item.title}</h3>
                                        <p className="item-chapters-mobile progressive-appear">
                                            {item.chapter_count && `${item.chapter_count} chapitres`}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default MobileCarouselMangaPopulaire;
