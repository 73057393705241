import React, { useState } from 'react';
import './SearchBar.css';
import {useNavigate} from "react-router-dom"; // Assurez-vous d'importer votre fichier CSS
import config from "../config";

function SearchBar({ searchValue, onSearchChange, toggleMenu, isMenuOpen }) {

    const navigate = useNavigate();

    // Fonction pour recharger la page
    const reloadPage = () => {
        navigate(`/`);

    };

    return (
        <div className='search-bar-container'>
            <div className="menu-icon" onClick={toggleMenu}>
                <div className="menu-btn">
                    <div className="menu-btn-burger"></div>
                </div>
            </div>
            {/* Ajoutez une classe conditionnelle pour afficher ou masquer le menu */}
            <div className={`menu ${isMenuOpen ? 'open' : ''}`}>
                {/* Contenu du menu */}
            </div>
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Recherche un manga"
                    className="search-input"
                    value={searchValue}
                    onChange={onSearchChange}
                />
                <i className="fas fa-search search-icon"></i>
            </div>
            <div className="site-icon" onClick={reloadPage}>
                <img src="KanjanLetter.svg" alt="Site Icon" className="icon-image" />
            </div>
        </div>
    );
}

export default SearchBar;
