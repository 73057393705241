import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './mangaList.css';
import { FaArrowRight } from 'react-icons/fa';
import config from "../config";


const Item = ({ icon, title, base_url, chapters }) => {
    const navigate = useNavigate();

    const handleItemClick = () => {
        navigate(`/manga/${encodeURIComponent(title)}`);
    };

    return (
        <div className="manga-item" onClick={handleItemClick}>
            <div className={"img-bg"}>
                {icon ? (
                    <img src={`${base_url}${icon}`} alt={title} className="item-image progressive-appear"/>
                ) : (
                    <div className="item-image"/> // Affichez un élément de remplacement ici
                )}
            </div>
            <h3 className="item-title progressive-appear">{title}</h3>
            <p className="item-chapters progressive-appear">{chapters !== null && chapters !== undefined ? chapters : ""}</p>
        </div>
    );
};

const Manga_list = ({base_url}) => {
    const [mangas, setMangas] = useState(Array(6).fill({})); // Initialiser avec 6 éléments vides
    const [mangaDetails, setMangaDetails] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${base_url}/${config.api}?action=get10Mangas`)
            .then(response => response.json())
            .then(data => {
                setMangas(data);
                // Pour chaque manga récupéré, obtenir le nombre de chapitres
                data.forEach(item => {
                    fetch(`${base_url}/${config.api}?action=getMangaChaptersCount&title=${encodeURIComponent(item.title)}`)
                        .then(response => response.json())
                        .then(details => {
                            setMangaDetails(prevDetails => ({
                                ...prevDetails,
                                [item.title]: details.chapters
                            }));
                        })
                        .catch(error => console.error(`Error fetching details for ${item.title}:`, error));
                });
            })
            .catch(error => console.error('Error fetching mangas:', error));
    }, [base_url]);

    const handleViewMoreClick = () => {
        // Naviguer vers la page où vous voulez montrer plus de mangas
        navigate('/manga/all'); // Mettez à jour avec votre chemin réel
    };

    return (
        <div className='manga-container'>
            <div className='collection-header'>
                <div className='text-collection'>Collection Manga</div>
                <button className='view-more-btn' onClick={handleViewMoreClick}>
                    <FaArrowRight className="icon-arrow-right" />
                </button>
            </div>
            <Swiper
                dir="ltr"
                spaceBetween={0}
                slidesPerView={'auto'}
                loop={true}
                freeMode={true}
            >
                {mangas.map((item, index) => (
                    <SwiperSlide key={index} style={{ width: 'auto' }}>
                        <Item
                            icon={item.icon}
                            title={item.title}
                            base_url={base_url}
                            chapters={mangaDetails[item.title] !== null && mangaDetails[item.title] !== undefined ? `${mangaDetails[item.title]} chapitres` : ""}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default Manga_list;
