import React from 'react';
import ReactDOM from 'react-dom/client'; // Correct import for React 18
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const disableRightClick = e => {
    e.preventDefault();
};

const disableDevShortcuts = e => {
    if (e.key === 'F12' || ((e.ctrlKey || e.metaKey) && (e.shiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C')))) {
        e.preventDefault();
    }
    if ((e.ctrlKey || e.metaKey) && e.key === 'U') {
        e.preventDefault();
    }
};

document.addEventListener('contextmenu', disableRightClick);
document.addEventListener('keydown', disableDevShortcuts);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( // Use the 'root.render' method for React 18

      <App />
);

reportWebVitals();
