import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import config from "../config";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import './css/carousel_manga_populaire_pc.css';

function PCCarouselWebtoonCollection({ base_url }) {
    const [items, setItems] = useState(Array(3).fill({}));
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDataLoaded, setIsDataLoaded] = useState(false); // Ajout de l'état pour vérifier si les données sont chargées
    const navigate = useNavigate();

    const handleItemClick = (title) => {
        navigate(`/manga/${encodeURIComponent(title)}`);
    };

    const handleItemClickCarousel = (title, countChapters, chapter) => {
        navigate(`/mobile-reader?title=${encodeURIComponent(title)}&countChapters=${countChapters}&chapter=chap_${chapter}`);
    };

    useEffect(() => {
        fetch(base_url + `/${config.api}?action=get10Webtoons`)
            .then(response => response.json())
            .then(data => {
                // Créer un tableau de promesses pour chaque élément dans data
                const promises = data.map(item => {
                    return fetch(`${base_url}/${config.api}?action=getMangaChaptersCount&title=${encodeURIComponent(item.title)}`)
                        .then(response => response.json())
                        .then(details => {
                            // Ajouter le nombre de chapitres à l'objet item
                            return { ...item, chapter_count: details.chapters };
                        });
                });

                // Attendre que toutes les promesses soient résolues
                Promise.all(promises)
                    .then(updatedItems => {
                        // Mettre à jour l'état items avec les éléments mis à jour
                        setItems(updatedItems);
                        console.log('Updated items:', updatedItems);
                        setIsDataLoaded(true); // Définir isDataLoaded sur true une fois les données chargées
                    })
                    .catch(error => console.error('Error fetching details for items:', error));
            })
            .catch(error => console.error('Error fetching popular mangas:', error));
    }, [base_url]);

    return (
        <div className="carousel-wrapper-mangapopulaire-pc">
            <div className={"HSpace"}></div>
            <div className='text-collection-manga-Populaire-pc'>Collection Manga</div>
            <Swiper
                className={"swiper-mangapopulaire-pc"}
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                centeredSlides={false}
                spaceBetween={30}
                slidesPerView={4}
                loop={true}
            >
                {items.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div
                            className={`slide-mobile-mangapopulaire-pc progressive-appear2 ${index === currentIndex ? "centered" : index === currentIndex - 1 || index === currentIndex + 1}`}
                            onClick={() => handleItemClickCarousel(item.title, item.chapter_count, 1)}
                            style={{backgroundImage: `url(${base_url + item.image})`}}
                        >
                            <div
                                className={`bg-img-carousel ${isDataLoaded ? 'hide-background' : ''}`}> {/* Ajouter la classe conditionnellement */}
                                <div className="slide-content-mobile-mangapopulaire-pc">

                                </div>
                                <div className="item-all-app-pc-mangaPopulaire" onClick={handleItemClick}>
                                    <div className={`bg-img-icon-carousel-pc ${isDataLoaded ? 'hide-background' : ''}`}>
                                        {item.icon ? (
                                            <img src={`${base_url}${item.icon}`} alt={item.title}
                                                 className="item-image-all-gallery-pc progressive-appear"/>
                                        ) : (
                                            <div className="item-image-all-gallery-pc"/> // Affichez un élément de remplacement ici
                                        )}
                                    </div>
                                    <div className={"item-icon-text-manga-populaire"}>
                                        <h3 className="item-title-mobile item-title-pc progressive-appear">{item.title}</h3>
                                        <p className="item-chapters-mobile item-chapters-pc progressive-appear">
                                            {item.chapter_count && `${item.chapter_count} chapitres`}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default PCCarouselWebtoonCollection;
