import React, { useEffect, useState, useRef } from 'react';
import './slide_recommandation.css';
import {useNavigate} from "react-router-dom";
import config from "../config";


const baseURL = config.base_url;
const Item = ({ icon, title, base_url, chapters  }) => {
    const navigate = useNavigate();

    const handleItemClick = (title) => {
        navigate(`/manga/${encodeURIComponent(title)}`);
    };


    return (
        <div className="manga-recommanded-item" onClick={() => handleItemClick(title)}>
            <img src={`${base_url}${icon}`} alt={title} className="item-image-recommanded"/>
        </div>
    );
};
const SlideRecommandation = ({ scans, isOpen, toggleSlide}) => {
    const [showSlide, setShowSlide] = useState(false);
    const slideRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        console.log(scans)
        const mangaImg = document.querySelector('.Manga_Page img');
        const scanImg = document.querySelector('.img-scan');

        const mangaImgHeight = mangaImg.offsetHeight;
        const scanImgHeight = scanImg.offsetHeight;

        const maxHeight = Math.max(mangaImgHeight, scanImgHeight);

        if (slideRef.current) {
            // slideRef.current.style.height = maxHeight + 'px';
        }
    }, []);



    return (
        <div className={`all-slide ${isOpen ? 'open' : ''}`} ref={slideRef}>
            <div className="slide-container">
                <div className="slide-fondu"></div>
                <div className="slide">
                    <h3 className={"title-lire-aussi"}>Lire aussi</h3>
                    {/* Mappez les scans pour les afficher */}
                    {scans.map((scan, index) => (
                        <div key={index} onClick={toggleSlide}>
                            <Item
                                icon={scan.image}  // Utilisez scan.icon au lieu de item.icon
                                title={scan.title}  // Utilisez scan.title au lieu de item.title
                                base_url={baseURL}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SlideRecommandation;
