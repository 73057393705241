import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './css/top_bar.css';
import { FaUser } from "react-icons/fa";

const TopBar = ({ searchValue, onSearchChange, toggleMenu }) => {
    const [hoveredTitle, setHoveredTitle] = useState('Home');

    const handleHover = (title) => {
        if (title !== '') {
            setHoveredTitle(title);
        }
    };


    useEffect(() => {
        let lastScrollTop = 0;
        const pcOnlyElement = document.querySelector('.App');
        const topBarElement = document.querySelector('.top-bar-pc');

        const handleScroll = () => {
            const st = pcOnlyElement.scrollTop;

            if (st > lastScrollTop){
                // Si on défile vers le bas
                topBarElement.classList.add('top-bar-pc-hidden');
            } else {
                // Si on défile vers le haut
                topBarElement.classList.remove('top-bar-pc-hidden');
            }
            lastScrollTop = st <= 0 ? 0 : st; // Pour les directions Mobile ou négatives
        };

        if (pcOnlyElement) {
            pcOnlyElement.addEventListener('scroll', handleScroll);

            // Cleanup function to remove the event listener when the component unmounts
            return () => pcOnlyElement.removeEventListener('scroll', handleScroll);
        }
    }, []);
    return (
        <div className="top-bar-pc">
            <div className="top-bar-content">
                <div className={"left-part"}>
                    <div className="logo">
                        <Link to="/">
                            <img src={"KanjanWord.svg"} alt="Site Icon" className="site-icon-text"/>
                        </Link>
                    </div>
                    <nav>
                        <ul>
                            <li>
                                <NavLink
                                    to="/"
                                    title="Home"
                                >
                                    Home
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    to="/catalogue"
                                    title="Catalogue"
                                >
                                    Catalogue
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className={"right-part"}>
                    <div className="search-bar-pc">
                        <input
                            type="text"
                            placeholder="Recherche un manga"
                            className="search-input"
                            value={searchValue}
                            onChange={onSearchChange}
                        />
                        <i className="fas fa-search search-icon"></i>
                    </div>
                    <div style={{width: "20px"}}></div>
                    {/*<div className="profil-icon">
                        <FaUser size={25}/>                    </div>*/}
                </div>
            </div>
        </div>
    );
};

export default TopBar;
