import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import "./App.css";

import Kanjan_image_mobile from './mobile/Kanjan_image_mobile';
import MobileCarousel from './mobile/Carousel_mobile';
import SearchBar from './mobile/SearchBar';
import Gallery_mobile from './mobile/Gallery_mobile';
import Manga_Page from './mobile/Manga_Page';
import MangaReader from './mobile/Manga_Reader';
import Webtoon_list from "./mobile/webtoonList";
import Manga_list from "./mobile/mangaList";
import AllMangasPage from "./mobile/AllMangasPage";
import AllWebtoonsPage from "./mobile/AllWebtoonsPage";
import MobileCarouselMangaPopulaire from "./mobile/Carousel_mangaPopulaire";
import MobileCarouselWebtoonPopulaire from "./mobile/Carousel_webtoonPopulaire";
import SideMenu from "./mobile/SideMenu";
import ProfilPage from "./mobile/Profil_mobile";
import CataloguePage from "./mobile/Catalogue_mobile";
import Carousel_mobile from "./mobile/Carousel_mobile";
import TopBar from "./pc/top_bar";
import PCCarouselMangaPopulaire from "./pc/carousel_manga_populaire_pc";
import { Helmet } from 'react-helmet';
import config from './config';
import Carousel_Home from "./pc/carousel_home";
import PCCarouselWebtoonPopulaire from "./pc/carousel_webtoon_populaire_pc";
import Manga_list_pc from "./pc/carousel_manga_collection_pc";
import Webtoon_list_pc from "./pc/old/webtoonList";
import PCCarouselMangaCollection from "./pc/carousel_manga_collection_pc";
import PCCarouselWebtoonCollection from "./pc/carousel_webtoon_collection_pc";

const baseURL = config.base_url;
// const baseURL = 'http://localhost/Scans/';

function HomePage() {
  const [carouselItems, setCarouselItems] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredGallery, setFilteredGallery] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Ajout de l'état du menu
  const navigate = useNavigate();

  useEffect(() => {


    // Appeler la route lors du chargement de la page d'accueil
    fetch( baseURL + `/${config.api}?action=homepageVisit`, { method: 'POST' })
        .then(response => response.json())
        .then(data => console.log('Homepage visit recorded:', data))
        .catch(error => console.error('Error recording homepage visit:', error));

    fetch(baseURL + `/${config.api}?action=getCarousel`)
        .then(response => response.json())
        .then(data => setCarouselItems(data))
        .catch(error => console.error('Error fetching carousel items:', error));

  }, []);

  useEffect(() => {
    if (gallery.length > 0 && searchTerm.length > 0) {
      const filterResults = (items, searchTerm) => {
        return items.filter(item =>
            item.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
      };
      setFilteredGallery(filterResults(gallery, searchTerm));
    } else {
      setFilteredGallery([]);
    }
  }, [searchTerm, gallery]);

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);

    // Effectuer une requête de recherche seulement si le terme de recherche est non vide
    if (searchTerm.trim() !== '') {
      try {
        const fetchScansByTitles = async () => {
          const response = await fetch(`${config.base_url}/${config.api}?action=searchMangas&searchTerm=${encodeURIComponent(searchTerm)}`);
          const data = await response.json();
          setGallery(data);
        };
        fetchScansByTitles();
      } catch (error) {
        console.error('Error fetching scans by titles:', error);
      }
    } else {
      // Si le terme de recherche est vide, réinitialiser la galerie
      setGallery([]);
    }
  };


  const handleItemClick = (title) => {
    navigate(`/manga/${encodeURIComponent(title)}`);
  };

  // Fonction pour basculer l'état du menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
      <div className="App">
        <Helmet>
          <title>Kanjan</title>
          <meta name="description" content="Lecture GRATUITE - Scans et Webtoons en VF - Vos œuvres préférées sur Kanjan : Solo Leveling / Boruto / One Piece / Jujutsu Kaisen, etc" />
        </Helmet>
        <div className="mobile-only">
          <SearchBar searchValue={searchTerm} onSearchChange={handleSearchChange} toggleMenu={toggleMenu}/>
          <SideMenu isOpen={isMenuOpen} toggleMenu={toggleMenu}/>
          {searchTerm.length > 0 ? (
              filteredGallery.length > 0 ? (
                  <div className="search-results-container">
                    {gallery.map((item) => (
                        <div key={item.id} className="gallery-item" onClick={() => handleItemClick(item.title)}>
                          {item && item.image && (
                            <img
                                src={baseURL + (item.image.startsWith('/') ? item.image.substring(1) : item.image)}
                                alt={item.title}
                                className="item-image-search"
                            />
                        )}

                          <div className="item-text">
                            <div className="item-text-1">{item.title}</div>
                            <div className="item-text-2">{item.info}</div>
                          </div>
                        </div>
                    ))}
                  </div>
              ) : (
                  <div className="no-results-message">
                    <div className="nores">
                      <img src="cry.png" width="200px" alt="No Results"/>
                      <span>Aucun scan trouvé</span>
                    </div>
                  </div>
              )
          ) : (
              <>
                <Carousel_mobile base_url={baseURL}/>
                <Manga_list base_url={baseURL}/>
                <MobileCarouselMangaPopulaire base_url={baseURL}/>
                <Webtoon_list base_url={baseURL}/>
                <MobileCarouselWebtoonPopulaire base_url={baseURL}/>
              </>
          )}
        </div>
        <div className="pc-only">
          <TopBar
              className="top-bar"
              searchValue={searchTerm}
              onSearchChange={handleSearchChange}
              toggleMenu={toggleMenu}
          />
          {searchTerm.length > 0 ? (
              filteredGallery.length > 0 ? (
                  <div className="search-results-container-pc">
                    {gallery.map((item) => (
                        <div key={item.id} className="gallery-item" onClick={() => handleItemClick(item.title)}>
                          {item && item.image && (
                              <img
                                  src={baseURL + (item.image.startsWith('/') ? item.image.substring(1) : item.image)}
                                  alt={item.title}
                                  className="item-image-search"
                              />
                          )}

                          <div className="item-text">
                            <div className="item-text-1">{item.title}</div>
                            <div className="item-text-2">{item.info}</div>
                          </div>
                        </div>
                    ))}
                  </div>
              ) : (
                  <div className="no-results-message-pc">
                    <div className="nores">
                      <img src="cry.png" width="200px" alt="No Results"/>
                      <span>Aucun scan trouvé</span>
                    </div>
                  </div>
              )
          ) : (
              <>
                {/*<Carousel_Home base_url={baseURL}/>*/}
                <div style={{marginTop: "100px"}}></div>
                <PCCarouselMangaPopulaire base_url={baseURL}/>
                <PCCarouselMangaCollection base_url={baseURL}/>
                <PCCarouselWebtoonPopulaire base_url={baseURL}/>
                <PCCarouselWebtoonCollection base_url={baseURL}/>
              </>
          )}
        </div>
      </div>
  );
}

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/profil" element={<ProfilPage/>}/>
          <Route path="/catalogue" element={<CataloguePage/>}/>
          <Route path="/manga/:title" element={<Manga_Page/>}/>
          <Route path="/mobile-reader" element={<MangaReader />} />
          <Route path="/manga/all" element={<AllMangasPage />} />
          <Route path="/webtoon/all" element={<AllWebtoonsPage />} />
        </Routes>
      </Router>
  );
}

export default App;
