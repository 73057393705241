import React, { useEffect, useState, useRef } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { MdSettings, MdChevronLeft, MdChevronRight } from 'react-icons/md';
import "./Manga_Reader.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import AdComponent from './AdComponent';
import { FaPlay, FaArrowLeft, FaList } from 'react-icons/fa';
import {  ImHome } from 'react-icons/im';
import { Mosaic } from 'react-loading-indicators';
import Kanjan_image_mobile from "./Kanjan_image_mobile";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import config from "../config";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";

const MangaReader = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [images, setImages] = useState([]);
    const [verticalImages, setverticalImages] = useState([]);
    const [isExpanded, setIsExpanded] = useState(true);


    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const chapterCount = parseInt(searchParams.get('countChapters'), 10);
    const chapter = searchParams.get('chapter');

    const formatChapter = (chapterString) => {
        const parts = chapterString.split('_');
        return `Chapitre ${parts[1]}`;
    };

    const currentChapterNumber = parseInt(chapter.split('_')[1]);
    const [loadingImages, setLoadingImages] = useState(true);
    const [imageLoadError, setImageLoadError] = useState(false); // Add this line
    const selectedChapterTitle = formatChapter(chapter);

    const base_url = config.base_url;
    // const base_url = 'http://localhost/Scans/';
    const title = searchParams.get('title');

    const swiperRef = useRef(null);

    const [readingDirection, setReadingDirection] = useState('ltr'); // 'ltr' pour gauche à droite, 'rtl' pour droite à gauche
    const [showDropdown, setShowDropdown] = useState(false);

    const [navigationMode, setNavigationMode] = useState('vertical'); // 'horizontal' ou 'vertical'

    const scrollRef = useRef(null);
    const [barsVisible, setBarsVisible] = useState(true);
    const [mangaDetails, setMangaDetails] = useState({});
    const [chapterNames, setChapterNames] = useState([]);
    const CACHE_KEY = 'MangaReaderCacheDatas';

    // Fonction pour sauvegarder les informations dans le cache
// Fonction pour sauvegarder les données dans le cache
    const saveToCache = (data) => {
        let cacheList = JSON.parse(localStorage.getItem(CACHE_KEY)) || []; // Récupérer la liste des données du cache

        // Vérifier si le manga existe déjà dans la liste
        const existingIndex = cacheList.findIndex(item => item.title === data.title);

        // Obtenir la date actuelle pour la mise à jour de la date de dernière lecture
        const currentDate = new Date().getTime();

        if (existingIndex === -1) {
            // Si le manga n'existe pas, ajouter une nouvelle entrée avec les données et la liste des chapitres lus
            const newData = {
                title: data.title,
                chapters: [data.currentChapterNumber], // Ajouter le chapitre courant à la liste des chapitres lus
                chapterCount: data.chapterCount,
                currentChapterNumber: data.currentChapterNumber,
                lastReadDate: currentDate, // Ajouter la date de la dernière lecture
            };
            // Ajouter le nouveau manga en tête de liste
            cacheList.unshift(newData);
        } else {

            // Si le manga existe déjà, mettre à jour les données et le placer en tête de liste
            const existingData = cacheList[existingIndex];
            // Vérifier si le chapitre courant n'existe pas déjà dans la liste des chapitres lus
            if (!existingData.chapters.includes(data.currentChapterNumber)) {
                existingData.chapters.push(data.currentChapterNumber); // Ajouter le chapitre courant à la liste des chapitres lus
            }
            // Mettre à jour les autres données du manga
            existingData.currentChapterNumber = data.currentChapterNumber;
            existingData.chapterCount = data.chapterCount;
            existingData.lastReadDate = currentDate; // Mettre à jour la date de dernière lecture

            // Supprimer l'ancienne entrée
            cacheList.splice(existingIndex, 1);
            // Ajouter le manga mis à jour en tête de liste
            cacheList.unshift(existingData);
        }

        // Enregistrer la liste mise à jour dans le cache
        localStorage.setItem(CACHE_KEY, JSON.stringify(cacheList));
    };
    // Fonction pour récupérer les informations du cache
    const getFromCache = () => {
        const data = localStorage.getItem(CACHE_KEY);
        return data ? JSON.parse(data) : null;
    };

    const toggleBarsVisibility = () => {
        setBarsVisible(prev => !prev);
    };

    const toggleNavigationMode = () => {
        const newMode = navigationMode === 'horizontal' ? 'vertical' : 'horizontal';
        setNavigationMode(newMode);
        // Pas besoin de changer readingDirection ici puisque verticalImages ne dépend pas de readingDirection
        if (swiperRef.current) {
            swiperRef.current.swiper.update(); // Mise à jour du Swiper pour appliquer les nouveaux paramètres
        }
    };

    const handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        if (scrollTop + clientHeight >= scrollHeight) {
            setBarsVisible(true);
        }
    };


    useEffect(() => {
        const ref = scrollRef.current;
        if (ref) {
            ref.addEventListener('scroll', handleScroll);
            return () => {
                ref.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    useEffect(() => {
        fetch(`${base_url}/${config.api}?action=getChapters&title=${title}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                if (Array.isArray(data)) {
                    setChapterNames(data.reverse());
                }
            })
            .catch((error) => {
                console.error('Error fetching chapter names:', error);
            });

        // Fetch manga details
        fetch(`${base_url}/${config.api}?action=getMangaDetailsByTitle&title=${title}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    setMangaDetails(data);
                    console.log(data)

                }
            })
            .catch((error) => {
                console.error('Error fetching manga details:', error);
            })

    }, [title]);




    useEffect(() => {
        // Assurez-vous que le titre et le numéro du chapitre sont disponibles
        if (title && chapter) {
            setLoadingImages(true);
            fetch(`${base_url}/${config.api}?action=getChapterImages&title=${encodeURIComponent(title)}&chapterNumber=${currentChapterNumber}`)
                .then(response => response.json())
                .then(data => {
                    // `data` est déjà un tableau d'objets avec les URLs, donc pas besoin de les trier ou les transformer
                    setImages(data.map(item => item.url)); // Extrait les URLs des objets
                    setverticalImages(data.map(item => item.url)); // Idem pour verticalImages si nécessaire
                })
                .catch(error => {
                    console.error('Error fetching images:', error);
                    setLoadingImages(false);
                }).finally(() => {
                setLoadingImages(false);
                setBarsVisible(true);
            });
        }

        const cachedData = getFromCache();
        const dataToCache = {
            title: title,
            chapter: chapter,
            chapterCount: chapterCount,
            currentChapterNumber: currentChapterNumber,
        };
        saveToCache(dataToCache);
        console.log(readingDirection)

        isRTL();

    }, [title, chapter, currentChapterNumber]); // Utilisez currentChapterNumber comme dépendance


    useEffect(() => {
        if (barsVisible && !showDropdown) { // Ne démarrez le compte à rebours que si le dropdown n'est pas affiché
            const timer = setTimeout(() => {
                setBarsVisible(false);
            }, 3000); // Disparaît après 3 secondes

            return () => clearTimeout(timer); // Nettoie le timer si l'état change avant l'expiration
        }
    }, [barsVisible, showDropdown]); // Ajoutez showDropdown comme dépendance


    useEffect(() => {
        const handleClickOutside = (event) => {
            // Vérifiez si le clic provient du menu déroulant ou de l'icône "Settings"
            if (
                showDropdown &&
                !event.target.closest('.dropdown-menu') &&
                !event.target.closest('.settings-icon')
            ) {
                // Le clic provient de l'extérieur du menu déroulant et de l'icône "Settings"
                setShowDropdown(false);
            }
        };

        // Ajoutez le gestionnaire d'événements pour les clics sur le document
        document.addEventListener('click', handleClickOutside);

        // Retirez le gestionnaire d'événements lorsque le composant est démonté
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showDropdown]);

    useEffect(() => {
        // Supposons que l'API peut vous dire si c'est un manga via un autre endpoint ou une propriété dans les données actuelles
        fetch(`${base_url}/${config.api}?action=getMangaTypeByTitle&title=${title}`)
            .then(response => response.json())
            .then(data => {
                // Supposons que 'data.contentType' est soit 'manga' soit 'other'
                const contentType = data.type;
                const isManga = contentType === 'manga';

                // Définir le mode de navigation en fonction du type de contenu
                // setNavigationMode(isManga ? 'horizontal' : 'vertical');
            })
            .catch(error => {
                console.error('Error fetching content type:', error);
            });
    }, []);



    const handleBackButtonClick = () => {
        navigate(`/manga/${title}`);
    };

    const handleGoHome = () => {
        navigate((`/`));
    };

    const handleNext = () => {
        navigateToNextChapter();
    };

    const handlePrevious = () => {
        navigateToPreviousChapter();
    };

    const navigateToNextChapter = () => {

        const nextChapterNumber = currentChapterNumber + 1;

        if (nextChapterNumber <= chapterCount) {
            if (swiperRef.current && navigationMode == "horizontal") {
                swiperRef.current.swiper.slideTo(0); // Réinitialiser la position du Swiper
            } else if (navigationMode == "vertical") {
                if (scrollRef.current) {
                    scrollRef.current.scrollTop = 0; // Remonte en haut du conteneur
                }
            }
            const queryParams = `?title=${title}&countChapters=${chapterCount}&chapter=chap_${nextChapterNumber}`;
            navigate(`/mobile-reader${queryParams}`);
        }
    };

    const navigateToPreviousChapter = () => {

        const previousChapterNumber = currentChapterNumber - 1;

        if (currentChapterNumber > 1) {
            if (swiperRef.current && navigationMode == "horizontal") {
                 swiperRef.current.swiper.slideTo(0); // Réinitialiser la position du Swiper
            } else if (navigationMode == "vertical") {
                if (scrollRef.current) {
                    scrollRef.current.scrollTop = 0; // Remonte en haut du conteneur
                }
            }
            const queryParams = `?title=${title}&countChapters=${chapterCount}&chapter=chap_${previousChapterNumber}`;
            navigate(`/mobile-reader${queryParams}`);
        }
    };

    const getDisplayedPageIndex = () => {
        // Calculer l'index de la page en fonction du sens de lecture
        return readingDirection === 'rtl' ? (images.length - currentImageIndex) : (currentImageIndex + 1);
    };

    const toggleReadingDirection = (direction) => {
        // Inverse le sens de lecture et inverse l'ordre des images
        setReadingDirection(currentDirection => currentDirection === 'ltr' ? 'rtl' : 'ltr');

        const reversedImages = [...images].reverse();
        setImages(reversedImages);

        const newIndex = images.length - 1 - currentImageIndex;
        swiperRef.current.swiper.slideTo(newIndex);
        setCurrentImageIndex(newIndex);
    };

    const isRTL = () => {
        if(readingDirection === "rtl")
        {
            console.log(readingDirection)
            /*const reversedImages = [...images].reverse();
            setImages(reversedImages);

*/
            const newIndex = images.length - 1 - currentImageIndex;
            swiperRef.current.swiper.slideTo(newIndex);
            //setCurrentImageIndex(newIndex);
        }
    }


/*   <div className="loading-spinner">
                    <Mosaic color="#E7344A" size="small" text="" textColor="" speedPlus={0}/>
                </div>*/

    const handleChapterClick = (chapter) => {
        // Assurez-vous que chapter est l'objet contenant les détails du chapitre, y compris le titre
        const chapterNumberMatch = chapter.title.match(/\d+/);
        if (chapterNumberMatch) {
            const chapterNumber = parseInt(chapterNumberMatch[0], 10);
            const formattedChapterName = `chap_${chapterNumber}`;

            const queryParams = `?title=${title}&countChapters=${chapterNames.length}&chapter=${formattedChapterName}`;
            navigate(`/mobile-reader${queryParams}`);

        }
    };

    const toggleExpand = () => setIsExpanded(!isExpanded);

    const ExpandableText = ({ text, maxLength, isExpanded }) => {
        const [isTextExpanded, setIsTextExpanded] = useState(isExpanded);

        useEffect(() => {
            setIsTextExpanded(isExpanded);
        }, [isExpanded]);

        return (
            <p className={ "description-text-pc-reader"} onClick={() => setIsTextExpanded(!isTextExpanded)}>
                {text.length <= maxLength || isTextExpanded ? text : `${text.substring(0, maxLength)}...`}
            </p>
        );
    };

    return (
        <div className={"reader-page"} >
            {loadingImages ? ( // Condition de chargement des images
        <></>
            ) : (
                <>
                    <div className={"mobile-only"}>
                        <div className="manga-reader-container" onClick={toggleBarsVisibility}>
                            <div className={`app-bar ${barsVisible || showDropdown ? '' : 'hidden'}`}>
                                <div className="app-bar-spacer"></div>
                                <div className="top-bar">
                                    <div className="back-button-reader" onClick={handleBackButtonClick}>
                                        <FaList/>
                                    </div>
                                </div>
                                <h1 className="chapter_name">{formatChapter(chapter)}</h1>
                                <div className="settings-icon" onClick={() => setShowDropdown(!showDropdown)}>
                                    <MdSettings/>
                                </div>
                            </div>
                            {showDropdown && (
                                <div className="dropdown-menu">
                                    <div className="toggle-container radio-group-custom">
                                        <input
                                            id="horizontal-mode"
                                            type="radio"
                                            name="navigationMode"
                                            value="horizontal"
                                            checked={navigationMode === 'horizontal'}
                                            onChange={() => setNavigationMode('horizontal')}
                                        />
                                        <label htmlFor="horizontal-mode">Horizontal</label>

                                        <input
                                            id="vertical-mode"
                                            type="radio"
                                            name="navigationMode"
                                            value="vertical"
                                            checked={navigationMode === 'vertical'}
                                            onChange={() => setNavigationMode('vertical')}
                                        />
                                        <label htmlFor="vertical-mode">Vertical</label>
                                    </div>
                                    {/*navigationMode === 'horizontal' && (
                                <div className="toggle-container">
                                    <span>sens manga</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={readingDirection === 'rtl'}
                                               onChange={toggleReadingDirection}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            )*/}
                                </div>
                            )}
                            <>
                                {navigationMode === 'horizontal' ? (
                                    <Swiper
                                        key={readingDirection}
                                        ref={swiperRef}
                                        rtl={readingDirection === 'rtl'}
                                        direction="horizontal"
                                        spaceBetween={15}
                                        slidesPerView={1}
                                        onSlideChange={(swiper) => setCurrentImageIndex(swiper.realIndex)}
                                        mousewheel={true}
                                    >
                                        {images.map((imageUrl, index) => (
                                            <SwiperSlide key={index}>
                                                <img className="manga-reader-image" src={imageUrl}
                                                     alt={`Chapter Image ${index + 1}`}/>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                ) : (
                                    <div className="vertical-scroll-container" ref={scrollRef} onScroll={handleScroll}
                                         style={{display: 'flex', flexDirection: 'column', gap: '0'}}>
                                        {images.map((imageUrl, index) => {
                                            return (
                                                <>
                                                    <LazyLoadImage
                                                        key={index}
                                                        className={`manga-reader-image ${loadingImages ? 'image-transition' : ''} lazyload-image`}
                                                        src={imageUrl}
                                                        alt={`Chapter Image ${index + 1}`}
                                                        effect="blur"
                                                        onError={(e) => {
                                                            if (!imageLoadError) { // Add this check
                                                                e.target.onerror = null; // Prevent an infinite loop
                                                                e.target.src = imageUrl.replace('.png', '.jpg'); // Load the image as .jpg if .png fails to load
                                                                setImageLoadError(true); // Set the error state to true
                                                            }
                                                        }}
                                                    />
                                                    {imageLoadError && <div>Erreur de chargement de l'image</div>}
                                                </>
                                            );
                                        })}
                                    </div>
                                )}
                            </>
                            <div className={`bottom-bar ${barsVisible || showDropdown ? '' : 'hidden'}`}>
                                <MdChevronLeft
                                    onClick={handlePrevious}
                                    className={currentChapterNumber === 1 ? "disabled" : ""}
                                />
                                <div className={'horizontal visible'} style={{scale: '1.1'}}>
                                    <ImHome
                                        onClick={handleGoHome}
                                    />
                                </div>

                                <MdChevronRight
                                    onClick={handleNext}
                                    className={currentChapterNumber + 1 > chapterCount ? "disabled" : ""}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"pc-only"}>
                        <div className= {`container-reader-pc ${isExpanded ? 'expanded' : ''}`}>
                            <div onClick={toggleExpand} className="expand-button-pc-reader" style={{textAlign: 'center'}}>
                                {isExpanded ?
                                    <IoIosArrowDown style={{ color: 'white', transform: 'scale(2)', marginTop: '5px' }}/>
                                    :
                                    <IoIosArrowUp style={{ color: 'black', transform: 'scale(1.5)' }}/>
                                }                            </div>
                            <div className='banner-reader-pc'>
                                <div className="overlay"></div>
                                <div className="img-container-reader-pc">
                                    <img className={`img-scan-reader-pc ${isExpanded ? 'expanded' : ''}`}
                                         src={base_url + mangaDetails.image}
                                         alt={mangaDetails.title}/>
                                </div>
                                <div className="manga_body-pc">
                                    <div onClick={toggleExpand}>
                                        <ExpandableText text={mangaDetails.description || ''} maxLength={200}
                                                        isExpanded={isExpanded}/>
                                    </div>
                                </div>
                            </div>
                            <div className="content-reader-pc">
                                <div className="top-row-reader-pc">
                                    <h1 className="chapter-name-reader-pc">{formatChapter(chapter)}</h1>

                                </div>
                                <div className="chapter_list-pc-reader">
                                    <ul>
                                        {chapterNames.map((chapter) => (
                                            <li
                                                key={chapter.id}
                                                onClick={() => handleChapterClick(chapter)}
                                                className={chapter.title === selectedChapterTitle ? 'selected-chapter' : ''}
                                            >
                                                  <span className="chapter-title-pc-reader">
                                                    {chapter.title}
                                                  </span>
                                                <span>#{chapter.number}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="bottom-row-reader-pc">
                                    <div
                                        className={`prev-container-reader-pc ${currentChapterNumber === 1 ? "disabled-reader-pc" : ""}`}
                                        onClick={handlePrevious}>
                                        <MdChevronLeft className="btn-reader-pc chevronleft-btn-reader-pc"/>
                                        <span className="prev-text-reader-pc">Précédent</span>
                                    </div>
                                    <ImHome className="btn-reader-pc home-btn-reader-pc" onClick={handleGoHome}
                                            style={{scale: '1.6'}}/>
                                    <div
                                        className={`next-container-reader-pc ${currentChapterNumber + 1 > chapterCount ? "disabled-reader-pc" : ""}`}
                                        onClick={handleNext}>
                                        <span className="next-text-reader-pc">Prochain</span>
                                        <MdChevronRight className="btn-reader-pc chevronright-btn-reader-pc"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="manga-reader-container-pc" onClick={toggleBarsVisibility}>
                            <div className="vertical-scroll-container" ref={scrollRef} onScroll={handleScroll}
                                 style={{display: 'flex', flexDirection: 'column', gap: '0'}}>
                                {images.map((imageUrl, index) => {
                                    return (
                                        <>
                                            <LazyLoadImage
                                                key={index}
                                                className={`manga-reader-image ${loadingImages ? 'image-transition' : ''} lazyload-image`}
                                                src={imageUrl}
                                                alt={`Chapter Image ${index + 1}`}
                                                effect="blur"
                                                onError={(e) => {
                                                    if (!imageLoadError) { // Add this check
                                                        e.target.onerror = null; // Prevent an infinite loop
                                                        e.target.src = imageUrl.replace('.png', '.jpg'); // Load the image as .jpg if .png fails to load
                                                        setImageLoadError(true); // Set the error state to true
                                                    }
                                                }}
                                            />
                                            {imageLoadError && <div>Erreur de chargement de l'image</div>}
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                </>
            )}
        </div>
    );
}
export default MangaReader;
