import React from 'react';
import './SliderMenu.css';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import config from "../config";

function SideMenu({ isOpen, toggleMenu }) {
    const navigate = useNavigate();

    const reloadPage = () => {
        navigate('/');
    };

    const goToProfile = () => {
        navigate('/profil');
    };

    const goToCatalogue = () => {
        navigate('/catalogue');
    };

    return (
        <>
            <div className={`overlay ${isOpen ? 'open' : ''}`} onClick={toggleMenu}></div>
            <div className={`side-menu ${isOpen ? 'open' : ''}`}>
                {/* Ligne 1: Image et bouton pour fermer le menu */}
                <div className="menu-line">
                    <img src="KanjanWord.svg" alt="Image"/>
                    <button onClick={toggleMenu} className="close-button"></button>
                </div>
                {/* Trait blanc */}
                <hr className="menu-divider"/>
                {/* Ligne 2: Accueil */}
                <div className="menu-line" onClick={reloadPage}>
                    <span>Accueil</span>
                </div>
                <hr className="menu-line-divider"/>
                {/* Ligne 3: Catalogue */}
                <div className="menu-line" onClick={goToCatalogue}>
                    <span>Catalogue</span>
                </div>
                <hr className="menu-line-divider"/>
                {/* Ligne 4: Profil */}
                <div className="menu-line" onClick={goToProfile}> {/* Appel de la fonction goToProfile au clic */}
                    <span>Profil</span>
                </div>
                <hr className="menu-line-divider"/>
            </div>
        </>
    );
}

export default SideMenu;
