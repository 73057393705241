import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import "./Profil_mobile.css";

import SearchBar from './SearchBar';
import SideMenu from "./SideMenu";
import {FaArrowRight} from "react-icons/fa";
import {Swiper, SwiperSlide} from "swiper/react";
import config from "../config";

const baseURL = config.base_url;
// const baseURL = 'http://localhost/Scans/';


const Item = ({ icon, title, base_url, chapters }) => {
  const navigate = useNavigate();

  return (
      <div className="manga-item">
        {icon && title && (
            <img src={`${base_url}${icon}`} alt={title} className="item-image progressive-appear" />
        )}
        {title && (
            <h3 className="item-title progressive-appear">{title}</h3>
        )}
        {chapters && (
            <p className="item-chapters progressive-appear">{chapters}</p>
        )}
      </div>
  );
};

function ProfilPage() {
  const [gallery, setGallery] = useState([]);
  const [cacheMangas, setCacheMangas] = useState([]);
  const [cacheMangasApi, setCacheMangasApi] = useState([]);
  const [cacheFavoritesMangas, setCacheFavoritesMangas] = useState([]);
  const [cacheFavoritesMangasApi, setCacheFavoritesMangasApi] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredGallery, setFilteredGallery] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Ajout de l'état du menu
  const navigate = useNavigate();

  const CACHE_KEY = 'MangaReaderCacheDatas';
  const FAVORITES_CACHE_KEY = 'Favorites';

  // Fonction pour récupérer les informations du cache
  const getFromCache = () => {
    const data = localStorage.getItem(CACHE_KEY);
    return data ? JSON.parse(data) : null;
  }

  const getFromCacheFavorite = () => {
    const data = localStorage.getItem(FAVORITES_CACHE_KEY);
    return data ? JSON.parse(data) : null;
  };

  useEffect(() => {
    fetch(baseURL + `/${config.api}?action=getAll`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => setGallery(data))
        .catch(error => console.error('Error fetching gallery data:', error));
  }, []);

  useEffect(() => {
    if (gallery.length > 0 && searchTerm.length > 0) {
      const filterResults = (items, searchTerm) => {
        return items.filter(item =>
            item.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
      };
      setFilteredGallery(filterResults(gallery, searchTerm));
    } else {
      setFilteredGallery([]);
    }
  }, [searchTerm, gallery]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleItemClickCarousel = (title, countChapters, chapter) => {
    navigate(`/mobile-reader?title=${encodeURIComponent(title)}&countChapters=${countChapters}&chapter=${chapter}`);
  };

  const handleItemClick = (title) => {
    navigate(`/manga/${encodeURIComponent(title)}`);
  };


  // Fonction pour basculer l'état du menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const cachedData = getFromCache();
    setCacheMangas(cachedData);
    if (cachedData) {
      const titles = cachedData.map(item => item.title);
      fetch(`${baseURL}/${config.api}?action=getScansByTitles&titles=${titles.join(',')}`)
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            setCacheMangasApi(data);
          })
          .catch(error => console.error('Error fetching gallery data by titles:', error));
    }

    const cachedDataFavorites = getFromCacheFavorite();
    setCacheFavoritesMangas(cachedDataFavorites);
    if (cachedDataFavorites) {
      const titles = cachedDataFavorites.map(item => item.title);
      fetch(`${baseURL}/${config.api}?action=getScansByTitles&titles=${titles.join(',')}`)
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            setCacheFavoritesMangasApi(data);
          })
          .catch(error => console.error('Error fetching gallery data by titles:', error));
    }
  }, []);


  const sortedCacheMangasApi = cacheMangas && cacheMangas.length > 0 ? cacheMangas.map(cacheItem => {
    if (!cacheMangasApi || cacheMangasApi.length === 0) {
      return null; // Ou une valeur par défaut appropriée si nécessaire
    }
    const apiItem = cacheMangasApi.find(apiItem => apiItem.title === cacheItem.title);
    return apiItem || null; // Retourner null si apiItem n'est pas trouvé
  }) : [];


  return (
      <div className="App">
        <div className="mobile-only">
          {/* Pass the toggleMenu function as a prop */}
          <SearchBar searchValue={searchTerm} onSearchChange={handleSearchChange} toggleMenu={toggleMenu}/>
          <SideMenu isOpen={isMenuOpen} toggleMenu={toggleMenu}/>
          {searchTerm.length > 0 ? (
              filteredGallery && filteredGallery.length > 0 ? (
                  <div className="search-results-container">
                    {filteredGallery.map((item) => (
                        item && item.id && item.title && item.image && (
                            <div key={item.id} className="searchResults" onClick={() => handleItemClick(item.title)}>
                              <img src={baseURL + (item.image.startsWith('/') ? item.image.substring(1) : item.image)}
                                   alt={item.title} className="item-image-search"/>
                              <div className="item-text-search">
                                <div className="item-text-1-search">{item.title}</div>
                                <div className="item-text-2-search">{item.info}</div>
                              </div>
                            </div>
                        )
                    ))}
                  </div>
              ) : (
                  <div className="no-results-message">
                    <div className="nores">
                      <img src="cry.png" width="200px" alt="No Results"/>
                      <span>No scan found</span>
                    </div>
                  </div>
              )
          ) : (
              <>
                <div className="profil-content">
                  <h3>YOUR PROFILE</h3>
                  <div className="bg-content">
                    <p className={"text-profil"}>Saves are linked to your browser. If you change
                      browser or device, you
                      will no longer have access to the same library.</p>
                    <p className={"text-profil"}>These backups are likely to lose their data and are
                      therefore preferably only a
                      temporary
                      tool, we advise you to update your lists as soon as possible on other sites
                      or
                      specialized applications.</p>
                    <hr className="red-divider"/>
                    <div className='profil-manga-container'>
                      <div className='collection-header'>
                        <div className='text-collection'>Resume your reading</div>
                      </div>
                      {cacheMangas !== null && cacheMangasApi !== null && cacheMangas.length > 0 && cacheMangasApi.length > 0 && (
                          <Swiper
                              dir="ltr"
                              spaceBetween={0}
                              slidesPerView={'auto'}
                              freeMode={true}
                          >
                            {sortedCacheMangasApi.map((item, index) => {
                              if (item ) {
                                if (!item || !cacheMangas) return null; // Check if item and cacheMangas are defined

                                const cachedItem = cacheMangas.find(cacheItem => {
                                  // Vérifier que cacheItem, cacheItem.title, et item.title existent
                                  if (cacheItem && cacheItem.title && item.title) {
                                    return cacheItem.title === item.title;
                                  }
                                  return false;
                                });

                                if (cachedItem) {
                                  return (
                                      <SwiperSlide key={index} style={{width: 'auto'}}>
                                        <div
                                            onClick={() => handleItemClickCarousel(item.title, cachedItem.chapterCount, `chap_${cachedItem.currentChapterNumber}`)}>
                                          <Item
                                              icon={item.icon}
                                              title={item.title}
                                              base_url={baseURL}
                                              chapters={"Chapter " + cachedItem.currentChapterNumber || 'Loading...'} // Pass the number of chapters
                                          />
                                        </div>
                                      </SwiperSlide>
                                  );
                                }
                              }
                              return null; // Return null if item or cachedItem is null
                            })}
                          </Swiper>
                      )}
                      {cacheMangas == null && (
                          <p className={"p-noCache"}>No reading found.</p>
                      )}
                    </div>
                    <div className='profil-manga-container'>
                      <div className='collection-header'>
                        <div className='text-collection'>Your favorites</div>
                      </div>
                      {cacheFavoritesMangas !== null && cacheFavoritesMangasApi !== null && cacheFavoritesMangas.length > 0 && cacheFavoritesMangasApi.length > 0 && (
                          <Swiper
                              dir="ltr"
                              spaceBetween={0}
                              slidesPerView={'auto'}
                              freeMode={true}
                          >
                            {cacheFavoritesMangasApi.map((item, index) => {
                              const cachedItem = cacheFavoritesMangas.find(cacheItem => cacheItem.title === item.title);
                              return (
                                  item && cachedItem && (
                                      <SwiperSlide key={index} style={{width: 'auto'}}>
                                        <div
                                            onClick={() => handleItemClick(item.title)}>
                                          <Item
                                              icon={item.icon}
                                              title={item.title}
                                              base_url={baseURL}
                                              chapters={cachedItem.chapterCount + " chapters" || 'Loading...'} // Pass the number of chapters
                                          />
                                        </div>
                                      </SwiperSlide>
                                  )
                              );
                            })}

                          </Swiper>
                      )}
                      {cacheFavoritesMangas === null || cacheFavoritesMangas.length === 0 ? (
                          <p className={"p-noCache"}>No favorites.</p>
                      ) : null}

                    </div>
                  </div>
                </div>

              </>
          )}
        </div>
      </div>
  );
}

export default ProfilPage;