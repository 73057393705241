import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { FaCircle, FaRegCircle } from "react-icons/fa";
import "./Catalogue_mobile.css";

import SearchBar from './SearchBar';
import SideMenu from "./SideMenu";
import config from '../config';
import TopBar from "../pc/top_bar";
import Carousel_Home from "../pc/carousel_home";
import PCCarouselMangaPopulaire from "../pc/carousel_manga_populaire_pc";
import PCCarouselMangaCollection from "../pc/carousel_manga_collection_pc";
import PCCarouselWebtoonPopulaire from "../pc/carousel_webtoon_populaire_pc";
import PCCarouselWebtoonCollection from "../pc/carousel_webtoon_collection_pc";

const baseURL = config.base_url;
// const baseURL = 'http://localhost/Scans/';

function CataloguePage() {
  const [gallery, setGallery] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredGallery, setFilteredGallery] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Ajout de l'état du menu
  const navigate = useNavigate();
  const [genres, setGenres] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [totalMangasLoaded, setTotalMangasLoaded] = useState(0);
  const [countManga, setCountManga] = useState(0);
  var listId = [];

  useEffect(() => {
    fetch(baseURL + `${config.api}?action=getAllGenres`)
        .then(response => response.json())
        .then(data => setGenres(data))
        .catch(error => console.error('Error fetching genres:', error));
    fetch(baseURL + `${config.api}?action=getScanCount`)
        .then(response => response.json())
        .then(data => setCountManga(data.scan_count))
        .catch(error => console.error('Error fetching genres:', error));
    setSelectedGenres([]);
  }, []);

  useEffect(() => {
    if (gallery.length > 0 && searchTerm.length > 0) {
      const filterResults = (items, searchTerm) => {
        return items.filter(item =>
            item.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
      };
      setFilteredGallery(filterResults(gallery, searchTerm));
    } else {
      setFilteredGallery([]);
    }
  }, [searchTerm]);


// Gestionnaire de changement pour la barre de recherche
  const handleSearchChange = async (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);

    // Effectuer une requête de recherche seulement si le terme de recherche est non vide
    if (searchTerm.trim() !== '') {

      // Si aucun genre n'est sélectionné, effectuer une requête de recherche
      try {
        const response = await fetch(`${config.base_url}${config.api}?action=searchMangas&searchTerm=${encodeURIComponent(searchTerm)}`);
        const data = await response.json();
        setFilteredGallery(data);
      } catch (error) {
        console.error('Error fetching scans by titles:', error);
      }
    }
    else {
      // Si le terme de recherche est vide, réinitialiser la galerie
      setFilteredGallery([]);
    }
  };


  const handleItemClick = (title) => {
    navigate(`/manga/${encodeURIComponent(title)}`);
  };
  const handleGenreClick = async (genre) => {
    // Vérifier si le genre est déjà sélectionné
    if (selectedGenres.includes(genre)) {
      // Retirer le genre de la liste des genres sélectionnés
      setSelectedGenres(prevSelectedGenres => prevSelectedGenres.filter(selectedGenre => selectedGenre !== genre));
    } else {
      // Ajouter le genre à la liste des genres sélectionnés
      setSelectedGenres(prevSelectedGenres => [...prevSelectedGenres, genre]);
    }
  };

// Utiliser useEffect pour surveiller les changements de selectedGenres et searchTerm
  useEffect(() => {
    try {
      // Convertir les genres sélectionnés en une chaîne de caractères
      const genresParam = selectedGenres.map(genre => typeof genre === 'string' ? genre : genre.name).join(',');
      // Effectuer une requête GET pour obtenir les mangas correspondants aux genres sélectionnés
      const fetchMangasByGenres = async () => {
        const response = await fetch(`${config.base_url}${config.api}?action=getMangasByGenres&genres=${genresParam}`);
        const data = await response.json();
        // Mettre à jour l'état de la galerie avec les mangas obtenus
        setGallery(data);
      };

      // Effectuer une requête GET pour obtenir les 10 premiers éléments si la barre de recherche est vide et aucun genre n'est sélectionné
      const fetchFirst10Elements = async () => {
        if (!searchTerm && selectedGenres.length === 0) {
          const response = await fetch(`${config.base_url}${config.api}?action=get25Elements`);
          const data = await response.json();
          for (const item of data) {
            listId.push(item.id);
          }
          setGallery(data);
        }
      };
      // Appeler la fonction pour effectuer la requête GET
      if(selectedGenres.length === 0){
        fetchFirst10Elements();
        fetchFirst10Elements();
      }
      fetchMangasByGenres();
    } catch (error) {
      console.error('Error fetching mangas by genres:', error);
    }


    const debounce = (func, delay) => {
      let debounceTimer;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      };
    };

    const handleScroll = debounce(() => {
      // Votre logique de récupération des éléments
      fetchElements();
    }, 250); // Ajustez le délai selon vos besoins

    const bgContentElement = document.querySelector('.bg-content');
    bgContentElement.addEventListener('scroll', handleScroll);

    const appElement = document.querySelector('.App');
    appElement.addEventListener('scroll', handleScroll);

    return () => {
      bgContentElement.removeEventListener('scroll', handleScroll);
      appElement.removeEventListener('scroll', handleScroll);
    };
  }, [selectedGenres]); // Déclencher l'effet à chaque fois que selectedGenres change

  const fetchElements = async () => {
    const bgContentElement = document.querySelector('.bg-content');
    const scrollPositionToLoad = bgContentElement.offsetHeight * 0.8;
    if (
        bgContentElement.scrollTop + bgContentElement.clientHeight >=
        scrollPositionToLoad && selectedGenres.length === 0
    ) {
      try {
        if (!searchTerm) {
          // Extraire les ID existants de la galerie actuelle
          const existingIds = listId;

          // Envoyer les ID existants dans la requête
          const response = await fetch(
              `${config.base_url}${config.api}?action=get25DifferentElements&existingIds=${existingIds.join(',')}`
          );
          const data = await response.json();
          setGallery((prevGallery) => [...prevGallery, ...data]);
          for (const item of data) {
            listId.push(item.id);
          }
        }
      } catch (error) {
        console.error('Error fetching more items:', error);
      }
    }
  };
  // Fonction pour basculer l'état du menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleItemClickCarousel = (title, countChapters, chapter) => {
    navigate(`/mobile-reader?title=${encodeURIComponent(title)}&countChapters=${countChapters}&chapter=chap_${chapter}`);
  };

  return (
      <div className="App">
        <div className="mobile-only">
          {/* Passer la fonction toggleMenu comme prop */}
          <SearchBar searchValue={searchTerm} onSearchChange={handleSearchChange} toggleMenu={toggleMenu}/>
          <SideMenu isOpen={isMenuOpen} toggleMenu={toggleMenu}/>
          {searchTerm.length >= 1 ? (
              filteredGallery.length > 0 ? (
                  <div className="search-results-container">
                    {filteredGallery.map((item) => (
                        <div className="gallery-item" onClick={() => handleItemClick(item.title)}>
                          <img src={baseURL + (item.image.startsWith('/') ? item.image.substring(1) : item.image)} alt={item.title} className="item-image-search"/>
                          <div className="item-text">
                            <div className="item-text-1">{item.title}</div>
                            <div className="item-text-2">{item.info}</div>
                          </div>
                        </div>
                    ))}
                  </div>
              ) : (
                  <div className="no-results-message">
                    <div className="nores">
                      <img src="cry.png" width="200px" alt="No Results"/>
                      <span>Aucun scan trouvé</span>
                    </div>
                  </div>
              )
          ) : (
              <>
                <div className="catalogue-content">
                  <h3>TOUTES NOS SERIES</h3>
                  <div className="bg-content">
                    <div className="genres-container">
                      {genres.map((genre, index) => (
                          <div
                              className={`genre-item ${selectedGenres.includes(genre) ? 'selected' : ''}`}
                              onClick={() => handleGenreClick(genre)}
                          >
                            {selectedGenres.includes(genre) ? <FaCircle className={"circle-selected"}/> : <FaRegCircle className={"circle-not-selected"}/>} {/* Utilisation des icônes */}
                            {typeof genre === 'string' ? genre : genre.name}
                          </div>
                      ))}
                    </div>
                    {/* Affichage du nombre de résultats lorsque la recherche n'est pas active */}
                    {!searchTerm && (
                        <div className="gallery-result-count">
                          {selectedGenres.length > 0
                              ? gallery.length
                              : countManga}
                          {" Résultats"}
                        </div>
                    )}
                    {/* Affichage de la galerie */}
                    <div className="gallery-container">
                      {gallery.map((item) => (
                          <div  className="gallery-item" onClick={() => handleItemClick(item.title)}>
                            <img src={baseURL + (item.image.startsWith('/') ? item.image.substring(1) : item.image)} alt={item.title} className="item-image-search"/>
                            <div className="item-text">
                              <div className="item-text-3">{item.title}</div>
                            </div>
                          </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
          )}
        </div>
        <div className="pc-only">
          <TopBar
              className="top-bar"
              searchValue={searchTerm} onSearchChange={handleSearchChange}
              toggleMenu={toggleMenu}
          />
          {searchTerm.length >= 1 ? (
              filteredGallery.length > 0 ? (
                  <div className="search-results-container-pc">
                    {filteredGallery.map((item) => (
                        <div  className="gallery-item" onClick={() => handleItemClickCarousel(item.title, item.chapter_count, 1)}>
                          <img src={baseURL + (item.image.startsWith('/') ? item.image.substring(1) : item.image)} alt={item.title} className="item-image-search"/>
                          <div className="item-text">
                            <div className="item-text-1">{item.title}</div>
                            <div className="item-text-2">{item.info}</div>
                          </div>
                        </div>
                    ))}
                  </div>
              ) : (
                  <div className="no-results-message">
                    <div className="nores">
                      <img src="cry.png" width="200px" alt="No Results"/>
                      <span>Aucun scan trouvé</span>
                    </div>
                  </div>
              )
          ) : (
              <>
                <div className="catalogue-content">
                  <h3 className={"titre-pc"}>TOUTES NOS SERIES</h3>
                  <div className="bg-content-pc">
                    <div className="genres-container-pc">
                      {genres.map((genre, index) => (
                          <div
                              className={`genre-item-pc ${selectedGenres.includes(genre) ? 'selected' : ''}`}
                              onClick={() => handleGenreClick(genre)}
                          >
                            {selectedGenres.includes(genre) ? <FaCircle className={"circle-selected"}/> :
                                <FaRegCircle className={"circle-not-selected"}/>} {/* Utilisation des icônes */}
                            {typeof genre === 'string' ? genre : genre.name}
                          </div>
                      ))}
                    </div>
                    {/* Affichage du nombre de résultats lorsque la recherche n'est pas active */}
                    {!searchTerm && (
                        <div className="gallery-result-count">
                          {selectedGenres.length > 0
                              ? gallery.length
                              : countManga}
                          {" Résultats"}
                        </div>
                    )}
                    {/* Affichage de la galerie */}
                    <div className="gallery-container-pc">
                      {gallery.map((item) => (
                          console.log(item),
                          <div className="gallery-item" onClick={() => handleItemClickCarousel(item.title, item.chapter_count, 1)}>
                            <img src={baseURL + (item.image.startsWith('/') ? item.image.substring(1) : item.image)}
                                 alt={item.title} className="item-image-search"/>
                            <div className="item-text">
                              <div className="item-text-3">{item.title}</div>
                            </div>
                          </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
          )}
        </div>
        </div>
  );
}

export default CataloguePage;
