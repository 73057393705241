import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AllMangasPage.css';
import { FaArrowLeft } from 'react-icons/fa';
import config from '../config';

const MangaItem = ({ icon, title, base_url, chapters }) => {
    const navigate = useNavigate();

    const handleItemClick = () => {
        navigate(`/manga/${encodeURIComponent(title)}`);
    };

    return (
        <div className="all-mangas-item" onClick={handleItemClick}>
            <img src={`${base_url}${icon}`} alt={title} className="all-mangas-item-image" />
            <div className={"title-and-chapterCount"}>
            <h3 className="all-mangas-item-title">{title}</h3>
            <p className="all-mangas-item-chapters">{chapters}</p></div>
        </div>
    );
};

const AllWebtoonsPage = () => {
    const base_url = config.base_url;
    const [mangas, setMangas] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${base_url}/${config.api}?action=getAllWebtoons`)
            .then(response => response.json())
            .then(data => {
                setMangas(data.map(manga => ({
                    ...manga,
                    chapters: 'Chargement...' // Initialiser avec un texte de chargement
                })));

                data.forEach(manga => {
                    fetch(`${base_url}/${config.api}?action=getMangaChaptersCount&title=${encodeURIComponent(manga.title)}`)
                        .then(response => response.json())
                        .then(details => {
                            setMangas(prevMangas => prevMangas.map(m => m.title === manga.title ? {...m, chapters: `${details.chapters} chapitres`} : m));
                        })
                        .catch(error => console.error('Erreur lors de la récupération du nombre de chapitres pour ' + manga.title, error));
                });
            })
            .catch(error => console.error('Erreur lors de la récupération des mangas', error));
    }, [base_url]);

    const handleBackButtonClick = () => {
        navigate('/'); // Adjust the path as needed
    };
    return (
        <div className='all-mangas-container'>
            <div className="all-mangas-header">
                <div className="back-button" onClick={handleBackButtonClick}>
                    <FaArrowLeft className="icon-shadow"/>
                </div>
                <h2 className='all-mangas-title'>Tous les Webtoons</h2>
            </div>
            <div className="all-mangas-list">
                {mangas.map((manga, index) => (
                    <MangaItem
                        key={index}
                        icon={manga.icon}
                        title={manga.title}
                        base_url={base_url}
                        chapters={manga.chapters}
                    />
                ))}
            </div>
        </div>
    );
};

export default AllWebtoonsPage;
