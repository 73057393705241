import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import "./Manga_Page.css";
import { FaPlay, FaArrowLeft } from 'react-icons/fa';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { IoIosArrowUp,IoIosArrowDown } from "react-icons/io";
import {  FaRegHeart, FaHeart } from "react-icons/fa6";
import { IoIosAdd } from 'react-icons/io';

import { Mosaic } from 'react-loading-indicators'; // Importez le composant Mosaic
import config from '../config';
import { FaPlus } from "react-icons/fa6";
import Slide from "./slide_recommandation";
import Slide_recommandation from "./slide_recommandation"; // Importez l'icône Plus

// Define the base URL up to /kanjan/
const base_url = config.base_url;
// const base_url = 'http://localhost/Scans/';

const Manga_Page = () => {
    const { title } = useParams();
    const navigate = useNavigate();
    const [mangaDetails, setMangaDetails] = useState({});
    const [chapterNames, setChapterNames] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Nouvel état loading

    const [isExpanded, setIsExpanded] = useState(false);
    const [visitedChapters, setVisitedChapters] = useState([])
    const [visitedLastChapter, setVisitedLastChapter] = useState();
    const [genres, setGenres] = useState([])
    const [isHearted, setIsHearted] = useState(false);
    const [favorites, setFavorites] = useState([]);

    const [showSlide, setShowSlide] = useState(false);
    const [scansByGenre, setScansByGenre] = useState([]);
    const [slideOpenedOnce, setSlideOpenedOnce] = useState(false);

    const toggleSlide = () => {
        setShowSlide(!showSlide);
        setSlideOpenedOnce(true);
    };
    const toggleHeart = () => {
        setIsHearted(!isHearted);
        const updatedFavorites = JSON.parse(localStorage.getItem(FAVORITES_CACHE_KEY)) || [];
        const existingIndex = updatedFavorites.findIndex(item => item.title === mangaDetails.title);
        if (existingIndex !== -1) {
            // Si le manga est déjà dans les favoris, supprimez-le de la liste
            updatedFavorites.splice(existingIndex, 1);
        } else {
            // Sinon, ajoutez-le à la liste des favoris
            updatedFavorites.push({ title: mangaDetails.title, url: base_url + mangaDetails.image, chapterCount: chapterNames.length});
        }
        setFavorites(updatedFavorites);
        localStorage.setItem(FAVORITES_CACHE_KEY, JSON.stringify(updatedFavorites));
    };


    const CACHE_KEY = 'MangaReaderCacheDatas';
    const FAVORITES_CACHE_KEY = 'Favorites';

    // Fonction pour récupérer les informations du cache
    const getFromCache = () => {
        const data = localStorage.getItem(CACHE_KEY);
        return data ? JSON.parse(data) : null;
    };

    const getFromCacheFavorite = () => {
        const data = localStorage.getItem(FAVORITES_CACHE_KEY);
        return data ? JSON.parse(data) : null;
    };


    const toggleExpand = () => setIsExpanded(!isExpanded);

    const handleChapterClick = (chapter) => {
        // Assurez-vous que chapter est l'objet contenant les détails du chapitre, y compris le titre
        const chapterNumberMatch = chapter.title.match(/\d+/);
        if (chapterNumberMatch) {
            const chapterNumber = parseInt(chapterNumberMatch[0], 10);
            const formattedChapterName = `chap_${chapterNumber}`;

            const queryParams = `?title=${title}&countChapters=${chapterNames.length}&chapter=${formattedChapterName}`;
            navigate(`/mobile-reader${queryParams}`);

            // Check if the clicked chapter is already in the visitedChapters array
            const isChapterVisited = visitedChapters.some((visitedChapter) => visitedChapter.number === chapterNumber);
            if (!isChapterVisited) {
                // If not, add it to the visitedChapters array
                setVisitedChapters((prevVisitedChapters) => [...prevVisitedChapters, { number: chapterNumber }]);
            }
        }
    };

    const handleBackButtonClick = () => {
        //navigate(-1); // Back to the previous URL
        navigate(`/`);
    };

    useEffect(() => {
        fetch(`${base_url}/${config.api}?action=getChapters&title=${title}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                if (Array.isArray(data)) {
                    setChapterNames(data.reverse());
                } else {
                    setError('Invalid data received');
                }
            })
            .catch((error) => {
                console.error('Error fetching chapter names:', error);
                setError(error.message);
            });

        // Fetch manga details
        fetch(`${base_url}/${config.api}?action=getMangaDetailsByTitle&title=${title}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    setError(data.error);
                } else {
                    setMangaDetails(data);
                    setGenres(data.genres);

                }
            })
            .catch((error) => {
                console.error('Error fetching manga details:', error);
                setError(error.message);
            })
            .finally(() => {
                setLoading(false);
            })

    }, [title]);

    useEffect(() => {
        // Fonction pour récupérer deux scans avec le même genre
        const fetchTwoScansByGenre = async () => {
            // Sélectionnez un genre aléatoire parmi la liste des genres disponibles
            const randomGenre = genres[Math.floor(Math.random() * genres.length)];

            // Appelez la route pour récupérer deux scans avec le même genre et exclure un titre spécifié
            const response = await fetch(`${base_url}/${config.api}?action=getTwoScansByGenre&genre=${randomGenre}&title=${title}`);
            const data = await response.json();


            // Vérifiez si au moins deux scans ont été retournés
            if (Array.isArray(data) && data.length >= 2) {
                setScansByGenre(data);
                // Faites ce que vous voulez avec ces scans
            }
        };

        // Appelez la fonction pour récupérer les scans
        fetchTwoScansByGenre();
    }, [genres]);

    useEffect(() => {
        const cachedData = getFromCache();
        if (cachedData) {

            const mangaData = cachedData.find(item => item.title === title);
            if (mangaData) {
                setVisitedLastChapter(mangaData.currentChapterNumber);
                setVisitedChapters(mangaData.chapters || []);
            }
        }

        const favoritesData = getFromCacheFavorite();
        if (favoritesData) {
            const isMangaInFavorites = favoritesData.find(item => item.title === title);
            setIsHearted(isMangaInFavorites);
            setFavorites(favorites);
        }
    }, [title]);

    /*
    if (loading) { // Affichez un indicateur de chargement tant que loading est true
        return (
            <div className="Manga_Page">
                <div className="mosaic-container">
                    <Mosaic color="#E7344A" size="small" text="" textColor="" speedPlus={0}/>
                </div>
            </div>
        );
    }
*/
    if (loading) { // Affichez un indicateur de chargement tant que loading est true
        return (
          <></>
        );
    }


    if (error) {
        return (
            <div>
                <h2>Error: {error}</h2>
            </div>
        );
    }
    const handleOrderByClick = () => {
        setChapterNames([...chapterNames].reverse());
    };

    const ExpandableText = ({ text, maxLength, isExpanded }) => {
        const [isTextExpanded, setIsTextExpanded] = useState(isExpanded);

        useEffect(() => {
            setIsTextExpanded(isExpanded);
        }, [isExpanded]);

        return (
            <p className={`expandable-text ${isTextExpanded ? 'expanded' : ''}`} onClick={() => setIsTextExpanded(!isTextExpanded)}>
                {text.length <= maxLength || isTextExpanded ? text : `${text.substring(0, maxLength)}...`}
            </p>
        );
    };

    function isNewChapter(chapter) {
        const dateString = chapter.date_insertion;
        const ONE_WEEK = 7 * 24 * 60 * 60 * 1000; // Durée d'une semaine en millisecondes
        const insertionDate = new Date(dateString); // Convertir la chaîne de date en objet Date
        const currentDate = new Date(); // Date actuelle

        // Calculer la différence entre la date actuelle et la date d'insertion en millisecondes
        const difference = currentDate - insertionDate;

        // Retourner true si la différence est inférieure à une semaine, sinon false
        return difference < ONE_WEEK;
    }

    return (
        <div className="Manga_Page">
            <div className="top-bar-right">
                <div className="back-button" onClick={handleBackButtonClick}>
                    <FaArrowLeft className="icon-shadow"/>
                </div>
            </div>

            <Slide_recommandation scans={scansByGenre} isOpen={showSlide} toggleSlide={toggleSlide}/>
            <div className={`top-bar-left ${showSlide ? 'shifted' : ''}`}>
                <div
                    className={`show-slide-button icon-shadow ${!showSlide ? 'rotate-backward' : 'rotate-forward'}`}
                    onClick={toggleSlide}>
                    <FaPlus className={`plus-icon button icon-shadow2`}/>
                    <div className={"micro-space"}></div>
                </div>

                <div className={`heart-button ${isHearted ? 'hearted' : 'heart'}`} onClick={toggleHeart}>
                    {isHearted ? <FaHeart className="boing-heart icon-shadow"/> :
                        <FaRegHeart className={"icon-shadow"}/>}
                </div>
            </div>
            <div className='banner'>
                <div className="overlay"></div>
                <img className={"img-scan"} src={base_url + mangaDetails.image} alt={mangaDetails.title}/>
            </div>

            <div className="manga_body">
                <div onClick={toggleExpand}>
                    <ExpandableText text={mangaDetails.description || ''} maxLength={200} isExpanded={isExpanded}/>
                </div>
                <div className={"control_buttons"}>
                    <div className='order_by' onClick={handleOrderByClick}>
                        <img src="https://kanjan.fr/sort.svg"/>
                    </div>
                </div>
            </div>
            <div onClick={toggleExpand} className="expand-button" style={{textAlign: 'center'}}>
                {isExpanded ? <IoIosArrowUp/> : <IoIosArrowDown/>}
            </div>
            <div className={"chapter_list"}>
                <ul>
                    {chapterNames.map((chapter) => {
                        const isNew = isNewChapter(chapter); // Fonction pour vérifier si le chapitre est nouveau
                        return (
                            <li key={chapter.id} onClick={() => handleChapterClick(chapter)}
                                className={`${visitedChapters.includes(chapter.number) ? 'visited' : ''} ${chapter.number === visitedLastChapter ? 'show-cornet' : ''}`}>
                                <span className="chapter-title">
                                    {chapter.title}
                                    {!visitedChapters.includes(chapter.number) && isNew && (
                                        <span
                                            className="new-text-container"> {/* Classe parente pour le titre et la classe .new-text-chapter */}
                                            <span className="new-text-chapter">Nouveau</span>
                                        </span>
                                    )}
                                </span>
                                <span>#{chapter.number}</span>
                                {chapter.number === visitedLastChapter &&
                                    <img className={"cornet"} src="https://kanjan.fr/cornet_chapitre.svg"/>}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Manga_Page;
