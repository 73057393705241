import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import config from "../config";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './Carousel_mobile.css';

function MobileCarousel({ base_url }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [cacheMangas, setCacheMangas] = useState([]);
    const [cacheMangasApi, setCacheMangasApi] = useState([]);
    const navigate = useNavigate();
    const CACHE_KEY = 'MangaReaderCacheDatas';

    const getFromCache = () => {
        const data = localStorage.getItem(CACHE_KEY);
        return data ? JSON.parse(data) : null;
    };

    const handleItemClickCarousel = (title, countChapters, chapter) => {
        navigate(`/mobile-reader?title=${encodeURIComponent(title)}&countChapters=${countChapters}&chapter=chap_${chapter}`);
    };

    const handleItemClick = (title) => {
        navigate(`/manga/${encodeURIComponent(title)}`);
    };

    const handleSlideChange = (swiper) => {
        setCurrentIndex(swiper.activeIndex);
    };

    const updateCachedData = async (cachedData) => {
        if (cachedData) {
            const titles = cachedData.map(item => item.title);
            const promises = titles.map(title => fetch(`${base_url}/${config.api}?action=getMangaChaptersCount&title=${encodeURIComponent(title)}`));
            return Promise.all(promises)
                .then(responses => Promise.all(responses.map(response => response.json())))
                .then(data => {
                    const updatedCachedData = cachedData.map((item, index) => {
                        const newData = data[index];
                        if (newData.error) {
                            console.log(`Manga non trouvé pour ${item.title}`);
                            return null;
                        } else {
                            const newChapterCount = newData.chapters;
                            const oldChapterCount = item.chapterCount;
                            if (newChapterCount > oldChapterCount && oldChapterCount === item.currentChapterNumber) {
                                item.newChapterAvailable = true;
                            } else {
                                item.newChapterAvailable = false;
                            }
                            return { ...item, chapterCount: newChapterCount };
                        }
                    }).filter(item => item !== null); // Filtrer les éléments nuls (mangas non trouvés)
                    setCacheMangas(updatedCachedData);
                    localStorage.setItem(CACHE_KEY, JSON.stringify(updatedCachedData));
                    return updatedCachedData;
                })
                .catch(error => {
                    console.error('Error updating cached data:', error);
                    return cachedData;
                });
        }
        return null;
    };

    useEffect(() => {
        const resetChapterCountToZero = (title) => {
            const cachedData = getFromCache();
            if (cachedData) {
                const updatedCachedData = cachedData.map(item => {
                    if (item.title === title) {
                        return { ...item, chapterCount: 418 };
                    }
                    return item;
                });
                setCacheMangas(updatedCachedData);
                localStorage.setItem(CACHE_KEY, JSON.stringify(updatedCachedData));
                return updatedCachedData; // Retourner les données mises à jour
            }
            return null; // Retourner null si les données en cache sont vides
        };

        const fetchDataAndUpdateCache = async () => {
            /*const updatedCachedData = resetChapterCountToZero("My Hero Academia"); // Appel de resetChapterCountToZero
            if (updatedCachedData) { // Vérification si les données sont disponibles
                setCacheMangas(updatedCachedData);

            }*/

            try {
                const cachedData = getFromCache();
                setCacheMangas(cachedData);
                localStorage.setItem(CACHE_KEY, JSON.stringify(cachedData));
                const updatedData = await updateCachedData(cachedData); // Utiliser les données mises à jour
                if (updatedData) {
                    const titles = updatedData.map(item => item.title); // Utiliser les données mises à jour
                    const response = await fetch(`${base_url}/${config.api}?action=getScansByTitles&titles=${titles.join(',')}`);
                    const data = await response.json();
                    setCacheMangasApi(data);
                }
            } catch (error) {
                console.error('Error updating and fetching data:', error);
            }
        };

        fetchDataAndUpdateCache();
    }, []);

    const sortedCacheMangasApi = cacheMangas && cacheMangas.length > 0 ? cacheMangas.map(cacheItem => {
        if (!cacheMangasApi || cacheMangasApi.length === 0) {
            return null; // Ou une valeur par défaut appropriée si nécessaire
        }
        const apiItem = cacheMangasApi.find(apiItem => apiItem.title === cacheItem.title);
        return apiItem || null; // Retourner null si apiItem n'est pas trouvé
    }) : [];

    return (
        <div>
            {cacheMangas && cacheMangas.length > 0 && ( // Vérifie si cachedData est défini
                <div className="carousel-wrapper">
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        centeredSlides={true}
                        spaceBetween={25}
                        slidesPerView={1.2}
                        onSlideChange={handleSlideChange}
                        autoplay={{delay: 4000}}
                    >
                        {sortedCacheMangasApi.length > 0 && (
                            sortedCacheMangasApi.slice(0, 5).map((item, index) => (
                                item && item.image && ( // Vérifiez que item et item.image ne sont pas null
                                    <SwiperSlide key={index}>
                                        <div
                                            className={`slide-mobile progressive-appear ${index === currentIndex ? "centered" : index === currentIndex - 1 || index === currentIndex + 1 ? "grayscale" : ""}`}
                                            style={{backgroundImage: `url(${base_url + (item.image)})`}}
                                            onClick={() => handleItemClickCarousel(item.title, cacheMangas[index].chapterCount, cacheMangas[index].currentChapterNumber)}
                                        >
                                            <p className="info-reprise">
                                                {cacheMangas[index].newChapterAvailable ? "Nouveau chapitre disponible !" : `Reprenez votre lecture au chapitre ${cacheMangas[index].currentChapterNumber}`}
                                            </p>
                                            <div className="slide-content-mobile">
                                                <div className="slide-container-mobile">
                                                    {/* Contenu supplémentaire */}
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            ))
                        )}
                    </Swiper>
                    <div className="indicators">
                        {sortedCacheMangasApi.filter(item => item && item.image).slice(0, 5).map((_, index) => (
                            <span key={index} className={index === currentIndex ? "indicator active" : "indicator"}/>
                        ))}
                    </div>

                </div>
            )}
        </div>
    );
}

export default MobileCarousel;
